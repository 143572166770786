import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable()
export class httpInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('token');

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });


        return next.handle(request).pipe(
            catchError(response => {
                if (response.status === 401) {
                    if (response.error.message === "Expired Token.") {
                        localStorage.clear()
                    }
                }
                return throwError(response);
            })
        )
    }

    // return next.handle(request).pipe(
    //     map((event: HttpEvent<any>) => {
    //         if (event instanceof HttpResponse) {
    //             // console.log('event--->>>', event);
    //         }
    //         return event;
    //     }));

}