export const path = 'assets/models';
import {
  initialPositions,
  setScale,
  setColor,
  setLength,
  hideRoofSideParts,
  setRailsHeight,
  adjustRoofPosition,
  setRoofHeight,
  setWidth,
  setVylet,
  setBottomHeight,
  setOtlyv,
  otkosSetWidth,
  otkosSetHeight,
  otkosSetDepth,
  GETCOLOR
} from './adjust.js'
import {
  Group
} from 'three';
import {
  animation
} from './animations.js'

export const models = [{
    id: "0",
    name: "Козырек на дымоход",
    file: `${path}/kozirek2_asm3.obj`,
    position: -2,
    nameEn: "KOZYREK",
    scale: 0.15,

    image: `${path}/model1.png`,

    _params: {
      SCALE: {
        value: 1,
        min: 0.3,
        max: 2,
        nameRu: "Масштаб",
        func: setScale
      },
      COLOR: {
        value: GETCOLOR(),
        nameRu: "Цвет",
        func: setColor
      },
      LENGTH: {
        value: 1550,
        min: 400,
        max: 3000,
        nameRu: "Длина",
        func: setLength
      },
      WIDTH: {
        value: 800,
        min: 300,
        max: 800,
        nameRu: "Ширина",
        func: setWidth
      },
      BOTTOMHEIGHT: {
        value: 150,
        min: 50,
        max: 200,
        nameRu: "Высота юбки",
        func: setBottomHeight
      },
      RAILSHEIGHT: {
        value: 300,
        min: 250,
        max: 400,
        nameRu: "Высота ножек",
        func: setRailsHeight
      },
      VYLET: {
        value: 150,
        min: 50,
        max: 150,
        nameRu: "Вылет",
        func: setVylet
      },
      TOPHEIGHT: {
        value: 200,
        min: 50,
        max: 400,
        nameRu: "Высота пирамиды",
        func: setRoofHeight
      }
    },
    _advancedParams: {
      HIDEROOFSIDEPARTS: {
        value: false,
        nameRu: 'Скрыть боковые части крыши',
        func: hideRoofSideParts
      }
    },
    _loaded3DModel: new Group()
  },
  {
    id: "1",
    name: "Откос",
    file: `${path}/otkos_asm.obj`,
    position: -2,
    nameEn: "OTKOS",
    scale: 0.15,
    image: `${path}/otkos.png`,
    _params: {
      SCALE: {
        value: 1,
        min: 0.3,
        max: 2,
        nameRu: "Масштаб",
        func: setScale
      },
      COLOR: {
        value: GETCOLOR(),
        nameRu: "Цвет",
        func: setColor
      },
      WIDTH: {
        value: 1000,
        min: 500,
        max: 3000,
        nameRu: "Ширина",
        func: otkosSetWidth
      },
      HEIGHT: {
        value: 1200,
        min: 500,
        max: 3000,
        nameRu: "Высота",
        func: otkosSetHeight
      },
      DEPTH: {
        value: 200,
        min: 100,
        max: 500,
        nameRu: "Глубина откоса",
        func: otkosSetDepth
      }
    },
    _advancedParams: {
      ANIMATION: {
        value: false,
        nameRu: "Анимация",
        func: animation
      },
      OTLYV: {
        value: true,
        nameRu: "Отлив",
        func: setOtlyv
      }
    },
    _loaded3DModel: new Group()
  },
]
