import { Component, OnInit } from '@angular/core';

import {
  modelObj
} from 'src/app/scene/global.js'

import {
  models
} from 'src/app/scene/models.js';
import { ChangemodelService } from '../services/changemodel.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  public params
  public advancedParams
  public _models = models
  currentRoute
  constructor(
    public _changeModelSvc: ChangemodelService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.init()
  }
  init() {
    this._changeModelSvc.getParams().subscribe(res => {
      if (!res) {
        return
      }
      this.currentRoute = res.id
      this.params = []
      this.advancedParams = []
      for (let KEY in res._params) {
        let OBJ = res._params[KEY]
        OBJ.KEY = KEY
        this.params.push(OBJ)
      }
      for (let KEY in res._advancedParams) {
        let OBJ = res._advancedParams[KEY]
        OBJ.KEY = KEY
        this.advancedParams.push(OBJ)
      }
    })
  }

  draw(params) {
    setTimeout(() => {
      for (let KEY in modelObj.currentModel._params) {
        let OBJ = modelObj.currentModel._params[KEY]
        if (OBJ.func) {
          OBJ.func()
        }
      }
    }, 1);
  }

  advFunc(item) {
    if (item.value) {
      item.value = false
    }
    else {
      item.value = true
    }
    item.func()
  }

  nextModel() {
    this.currentRoute++;
    if (this.currentRoute >= this._models.length) {
      this.currentRoute = 0;
    };
    this._router.navigate(['forms/'], { queryParams: { modelId: this.currentRoute } });
  }
}
