import {
  Box3,
  Box3Helper,
  Geometry,
  Raycaster,
  Mesh,
  Color,
  Vector3
} from 'three';
// import * as CANNON from 'three/examples/js/libs/cannon.js'
// import {
//     CannonPhysics
// } from 'three/examples/jsm/physics/CannonPhysics.js';
import {
  VertexNormalsHelper
} from 'three/examples/jsm/helpers/VertexNormalsHelper.js'
import {
  settings,
  addPlane,
  plane,
} from './settings.js';

import {
  sceneObj,
  modelObj
} from './global.js'

// window.CANNON = CANNON
// console.log(CANNON)
// var physics = new CannonPhysics();

var yPos,
  xPosMin,
  xPosMax,
  zPosMin,
  zPosMax,
  yPos2,
  vec1,
  vec2,
  zPosMin2,
  zPosMax2,
  xPosMin2,
  xPosMax2,
  kSHIRINY = 0.15,
  yPos3,
  boundingBoxHelper,
  boundingBoxHelper2,
  currentRoofPositionY = 0,
  tempBox = new Box3();

let COLORS = [0xff64d8, 0xfffd6b, 0xffd6ff, 0xf673ff, 0xfcd5ff, 0xffadb4, 0xffec80, 0x00ffcc, 0xffa1ff, 0x00f9ff, 0x00ffd9]

export function GETCOLOR() {
  let COLOR = COLORS[Math.floor(Math.random() * COLORS.length)]
  return COLOR
}

export function centerModels(model) {
  const box = tempBox.setFromObject(model);
  const center = box.getCenter(new Vector3());
  model.position.x += (sceneObj.scene.position.x - center.x)
  model.position.y += (sceneObj.scene.position.y - center.y);
  model.position.z += (sceneObj.scene.position.z - center.z);
}

export function getSize(obj) {
  const box = tempBox.setFromObject(obj);
  const size = box.getSize(new Vector3());
  return size
}

export function adjustFloor() {
  if (!settings.advanced.FLOOR.value) return;
  if (!plane) {
    addPlane()
  }
  const box = tempBox.setFromObject(modelObj.current3DModel);
  plane.position.y = box.min.y;
}

export function initialPositions() {
  modelObj.current3DModel.traverse(mesh => {

    if (mesh.name === 'KOZYREK4') {
      const value = 1

      mesh.scale.z = modelObj.currentModel.scale * value;
      tempBox.setFromObject(mesh);
      zPosMin = tempBox.min.z / modelObj.currentModel._params.SCALE.value
      zPosMax = tempBox.max.z / modelObj.currentModel._params.SCALE.value
    }

    if (mesh.name === 'KOZYREK18') {
      tempBox.setFromObject(mesh);
      yPos2 = (38.474839782714845 + tempBox.max.y) / modelObj.currentModel._params.SCALE.value
    }

    if (mesh.name === 'KOZYREK33') {
      tempBox.setFromObject(mesh);
      xPosMax2 = tempBox.max.x / modelObj.currentModel._params.SCALE.value
      xPosMin2 = tempBox.max.x / modelObj.currentModel._params.SCALE.value
    }
  })
}

export function setScale() {
  modelObj.current3DModel.scale.set(modelObj.currentModel._params.SCALE.value, modelObj.currentModel._params.SCALE.value, modelObj.currentModel._params.SCALE.value)
}

export function setColor() {
  modelObj.current3DModel.traverse(res => {
    if (res instanceof Mesh) {
      res.material.color = new Color(modelObj.currentModel._params.COLOR.value);
    }
  })
}

export function setLength() {
  modelObj.current3DModel.traverse(mesh => {
    if (mesh.name === 'KOZYREK5') {
      mesh.scale.x = modelObj.currentModel.scale * (modelObj.currentModel._params.LENGTH.value / 785);
      tempBox.setFromObject(mesh);
      xPosMin = tempBox.min.x / modelObj.currentModel._params.SCALE.value
      xPosMax = tempBox.max.x / modelObj.currentModel._params.SCALE.value
    }

    if (mesh.name === "KOZYREK33") {
      mesh.scale.x = modelObj.currentModel.scale * (modelObj.currentModel._params.LENGTH.value / 372);
      tempBox.setFromObject(mesh);
      xPosMin2 = tempBox.min.x / modelObj.currentModel._params.SCALE.value
      xPosMax2 = tempBox.max.x / modelObj.currentModel._params.SCALE.value
    }
    if (mesh.name === "KOZYREK34" || mesh.name === "KOZYREK35") {
      mesh.scale.x = modelObj.currentModel.scale * (modelObj.currentModel._params.LENGTH.value / 372);
    }

    setTimeout(() => {
      adjustModelsX(mesh, modelObj.currentModel._params.LENGTH.value / 785)
    }, 0.1);
  })
}

function displayVector() {
  modelObj.current3DModel.traverse(mesh => {
    if (mesh.name === 'KOZYREK34' || mesh.name === 'KOZYREK6') {
      if (mesh.name === 'KOZYREK34') {
        vec1 = new Box3().setFromObject(mesh);

      }
      if (mesh.name === 'KOZYREK6') {
        vec2 = new Box3().setFromObject(mesh);
      }

      function distanceVector(v1, v2) {
        // console.log(v1, v2)
        var dx = v1 - v2;
        return Math.sqrt(dx * dx);
      }
      setTimeout(() => {

        var distance = distanceVector(vec1.max.z, vec2.max.z);
        console.log('Вылет:', distance / modelObj.currentModel.scale)
      }, 0.1);

    }

  })
}

function adjustModelsX(mesh, value) {
  if (mesh.name === 'KOZYREK5' ||
    mesh.name === 'KOZYREK6' ||
    mesh.name === 'KOZYREK15' ||
    mesh.name === 'KOZYREK16' ||
    mesh.name === 'KOZYREK26' ||
    mesh.name === 'KOZYREK28') {
    mesh.scale.x = modelObj.currentModel.scale * value;
  }
  if (mesh.name === 'KOZYREK1' ||
    mesh.name === 'KOZYREK2' ||
    mesh.name === 'KOZYREK3' ||
    mesh.name === 'KOZYREK4' ||
    mesh.name === 'KOZYREK7' ||
    mesh.name === 'KOZYREK8' ||
    mesh.name === 'KOZYREK19' ||
    mesh.name === 'KOZYREK20' ||
    mesh.name === "KOZYREK21" ||
    mesh.name === "KOZYREK22" ||
    mesh.name === "KOZYREK25") {
    mesh.position.x = 296.6 * modelObj.currentModel.scale + xPosMin;
  }
  if (mesh.name === 'KOZYREK9' ||
    mesh.name === 'KOZYREK10' ||
    mesh.name === 'KOZYREK11' ||
    mesh.name === 'KOZYREK12' ||
    mesh.name === 'KOZYREK13' ||
    mesh.name === 'KOZYREK14' ||
    mesh.name === 'KOZYREK17' ||
    mesh.name === 'KOZYREK18' ||
    mesh.name === "KOZYREK23" ||
    mesh.name === "KOZYREK24" ||
    mesh.name === "KOZYREK27") {
    mesh.position.x = -296.6 * modelObj.currentModel.scale + xPosMax;
  }
  if (mesh.name === "KOZYREK29" ||
    mesh.name === "KOZYREK30") {
    mesh.position.x = 123.333 * modelObj.currentModel.scale + xPosMin2;
  }
  if (mesh.name === "KOZYREK31" ||
    mesh.name === "KOZYREK32") {
    mesh.position.x = -123.333 * modelObj.currentModel.scale + xPosMax2;
  }
}

function adjustModelsY(mesh, value) {
  if (mesh.name === 'KOZYREK2' ||
    mesh.name === 'KOZYREK6' ||
    mesh.name === 'KOZYREK12' ||
    mesh.name === 'KOZYREK14' ||
    mesh.name === 'KOZYREK10' ||
    mesh.name === 'KOZYREK16' ||
    mesh.name === 'KOZYREK8' ||
    mesh.name === 'KOZYREK4') {
    mesh.scale.y = modelObj.currentModel.scale * value;
  }
  if (mesh.name === 'KOZYREK1' ||
    mesh.name === 'KOZYREK5' ||
    mesh.name === 'KOZYREK11' ||
    mesh.name === 'KOZYREK13' ||
    mesh.name === 'KOZYREK9' ||
    mesh.name === 'KOZYREK15' ||
    mesh.name === 'KOZYREK7' ||
    mesh.name === 'KOZYREK3' ||
    mesh.name === "KOZYREK17" ||
    mesh.name === "KOZYREK18" ||
    mesh.name === "KOZYREK19" ||
    mesh.name === "KOZYREK20") {
    mesh.position.y = -97 * modelObj.currentModel.scale + yPos;
  }
}

function adjustModelsY2(mesh, value) {

  if (mesh.name === "KOZYREK17" ||
    mesh.name === "KOZYREK18" ||
    mesh.name === "KOZYREK19" ||
    mesh.name === "KOZYREK20") {
    mesh.scale.y = modelObj.currentModel.scale * value;
  }
}

function adjustModelsY3(mesh) {
  if (mesh.name === "KOZYREK33") {
    tempBox.setFromObject(mesh)
    yPos3 = (38.474839782714845 + tempBox.min.y) / modelObj.currentModel._params.SCALE.value
  }
  setTimeout(() => {
    if (mesh.name === 'KOZYREK34' ||
      mesh.name === 'KOZYREK35' ||
      mesh.name === "KOZYREK30" ||
      mesh.name === "KOZYREK32") {
      mesh.position.y = -306.66 * modelObj.currentModel.scale + yPos3;
    }
  }, 1);
}

function adjustModelsZ(mesh, value) {
  if (mesh.name === 'KOZYREK4' ||
    mesh.name === 'KOZYREK14' ||
    mesh.name === 'KOZYREK13' ||
    mesh.name === 'KOZYREK3' ||
    mesh.name === 'KOZYREK25' ||
    mesh.name === 'KOZYREK27') {
    mesh.scale.z = modelObj.currentModel.scale * value;
  }
  if (mesh.name === 'KOZYREK10' ||
    mesh.name === 'KOZYREK9' ||
    mesh.name === 'KOZYREK15' ||
    mesh.name === 'KOZYREK16' ||
    mesh.name === 'KOZYREK7' ||
    mesh.name === 'KOZYREK8' ||
    mesh.name === 'KOZYREK17' ||
    mesh.name === 'KOZYREK20' ||
    mesh.name === "KOZYREK22" ||
    mesh.name === "KOZYREK23" ||
    mesh.name === "KOZYREK28") {
    mesh.position.z = 166.66 * modelObj.currentModel.scale + zPosMin;
  }
  if (mesh.name === 'KOZYREK1' ||
    mesh.name === 'KOZYREK2' ||
    mesh.name === 'KOZYREK5' ||
    mesh.name === 'KOZYREK6' ||
    mesh.name === 'KOZYREK11' ||
    mesh.name === 'KOZYREK12' ||
    mesh.name === 'KOZYREK19' ||
    mesh.name === 'KOZYREK18' ||
    mesh.name === "KOZYREK21" ||
    mesh.name === "KOZYREK24" ||
    mesh.name === "KOZYREK26") {
    mesh.position.z = -166.66 * modelObj.currentModel.scale + zPosMax;
  }
  if (mesh.name === "KOZYREK33" ||
    mesh.name === "KOZYREK29" ||
    mesh.name === "KOZYREK31" ||
    mesh.name === "KOZYREK30" ||
    mesh.name === "KOZYREK32") {

    const length = ((zPosMax / modelObj.currentModel.scale) * 2 + 2 * modelObj.currentModel._params.VYLET.value + 200) / 728

    kSHIRINY = modelObj.currentModel.scale * length

    mesh.scale.z = kSHIRINY;
  }
}

function adjustModelsZ2(mesh) {
  if (mesh.name === 'KOZYREK33') {
    tempBox.setFromObject(mesh);
    zPosMin2 = tempBox.min.z / modelObj.currentModel._params.SCALE.value
    zPosMax2 = tempBox.max.z / modelObj.currentModel._params.SCALE.value
  }
  if (mesh.name === 'KOZYREK34') {
    mesh.position.z = -364 * modelObj.currentModel.scale - zPosMin2
  }
  if (mesh.name === 'KOZYREK35') {
    mesh.position.z = 364 * modelObj.currentModel.scale + zPosMin2;
  }
}

export function hideRoofSideParts() {

  if (modelObj.currentModel._advancedParams.HIDEROOFSIDEPARTS.value === true) {
    modelObj.current3DModel.traverse(mesh => {
      if (mesh.name === "KOZYREK29" ||
        mesh.name === "KOZYREK30" ||
        mesh.name === "KOZYREK31" ||
        mesh.name === "KOZYREK32") {
        mesh.material.opacity = 0
        mesh.material.transparent = true;
        mesh.castShadow = false;
      }
    })
  } else {
    modelObj.current3DModel.traverse(mesh => {
      if (mesh.name === "KOZYREK29" ||
        mesh.name === "KOZYREK30" ||
        mesh.name === "KOZYREK31" ||
        mesh.name === "KOZYREK32") {
        mesh.material.opacity = settings.advanced.OPACITY.value
        mesh.castShadow = true;
      }
    })
  }
}

export function setBottomHeight() {
  modelObj.current3DModel.traverse(mesh => {
    if (mesh.name === 'KOZYREK2') {
      mesh.scale.y = modelObj.currentModel.scale * (modelObj.currentModel._params.BOTTOMHEIGHT.value / 100)
      tempBox.setFromObject(mesh);
      yPos = (38.474839782714845 + tempBox.max.y) / modelObj.currentModel._params.SCALE.value
    }

    setTimeout(() => {
      adjustModelsY(mesh, modelObj.currentModel._params.BOTTOMHEIGHT.value / 100)
      afterAdjust()
      adjustModelsY3(mesh)

    }, 0.1);

    function afterAdjust() {
      if (mesh.name === 'KOZYREK20') {
        tempBox.setFromObject(mesh);
        yPos2 = (38.474839782714845 + tempBox.max.y) / modelObj.currentModel._params.SCALE.value
      }

      if (mesh.name === "KOZYREK21" ||
        mesh.name === "KOZYREK22" ||
        mesh.name === "KOZYREK23" ||
        mesh.name === "KOZYREK24") {
        mesh.position.y = -370.333 * modelObj.currentModel.scale + yPos2;
      }

      if (mesh.name === "KOZYREK25" ||
        mesh.name === "KOZYREK26" ||
        mesh.name === "KOZYREK27" ||
        mesh.name === "KOZYREK28") {
        mesh.position.y = -370.333 * modelObj.currentModel.scale + yPos2;
      }

      if (mesh.name === "KOZYREK33" ||
        mesh.name === "KOZYREK29" ||
        mesh.name === "KOZYREK31") {
        mesh.position.y = currentRoofPositionY - 370.333 * modelObj.currentModel.scale + yPos2;
      }
    }

  })
}

export function setRailsHeight() {
  modelObj.current3DModel.traverse(mesh => {
    if (mesh.name === 'KOZYREK18') {

      mesh.scale.y = modelObj.currentModel.scale * (modelObj.currentModel._params.RAILSHEIGHT.value / 275)
      tempBox.setFromObject(mesh);
      yPos2 = (38.474839782714845 + tempBox.max.y) / modelObj.currentModel._params.SCALE.value
    }

    setTimeout(() => {
      adjustModelsY2(mesh, modelObj.currentModel._params.RAILSHEIGHT.value / 275)
      afterAdjust()
    }, 0.1);


    function afterAdjust() {
      if (mesh.name === "KOZYREK21" ||
        mesh.name === "KOZYREK22" ||
        mesh.name === "KOZYREK23" ||
        mesh.name === "KOZYREK24") {
        mesh.position.y = -370.333 * modelObj.currentModel.scale + yPos2;
      }

      if (mesh.name === "KOZYREK25" ||
        mesh.name === "KOZYREK26" ||
        mesh.name === "KOZYREK27" ||
        mesh.name === "KOZYREK28") {
        mesh.position.y = -370.333 * modelObj.currentModel.scale + yPos2;
      }

      if (mesh.name === "KOZYREK33" ||
        mesh.name === "KOZYREK29" ||
        mesh.name === "KOZYREK31") {
        mesh.position.y = currentRoofPositionY - 370.333 * modelObj.currentModel.scale + yPos2;
      }

      adjustModelsY3(mesh)

    }
  })
}

export function setWidth() {
  modelObj.current3DModel.traverse(mesh => {

    if (mesh.name === 'KOZYREK4') {

      mesh.scale.z = modelObj.currentModel.scale * (modelObj.currentModel._params.WIDTH.value / 525);

      tempBox.setFromObject(mesh);
      zPosMin = tempBox.min.z / modelObj.currentModel._params.SCALE.value
      zPosMax = tempBox.max.z / modelObj.currentModel._params.SCALE.value
    }

    setTimeout(() => {
      adjustModelsZ(mesh, modelObj.currentModel._params.WIDTH.value / 525)
      adjustModelsZ2(mesh)
    }, 1);

  })
}

export function setVylet() {
  modelObj.current3DModel.traverse(mesh => {

    if (mesh.name === "KOZYREK33" ||
      mesh.name === "KOZYREK29" ||
      mesh.name === "KOZYREK31" ||
      mesh.name === "KOZYREK30" ||
      mesh.name === "KOZYREK32") {
      tempBox.setFromObject(mesh);
      zPosMin2 = tempBox.min.z / modelObj.currentModel._params.SCALE.value
      zPosMax2 = tempBox.max.z / modelObj.currentModel._params.SCALE.value

      const length = ((zPosMax / modelObj.currentModel.scale) * 2 + 2 * modelObj.currentModel._params.VYLET.value + 200) / 728

      kSHIRINY = modelObj.currentModel.scale * length
      mesh.scale.z = kSHIRINY

    }
    setTimeout(() => {
      if (mesh.name === "KOZYREK33") {

        tempBox.setFromObject(mesh);
        zPosMin2 = tempBox.min.z / modelObj.currentModel._params.SCALE.value
        zPosMax2 = tempBox.max.z / modelObj.currentModel._params.SCALE.value
      }

      if (mesh.name === 'KOZYREK34') {
        mesh.position.z = -364 * modelObj.currentModel.scale - zPosMin2
      }
      if (mesh.name === 'KOZYREK35') {
        mesh.position.z = 364 * modelObj.currentModel.scale + zPosMin2;
      }
    }, 1);

  })
}



export function adjustRoofPosition() {
  return
  var firstBB,
    secondBB
  modelObj.current3DModel.traverse(mesh => {
    if (mesh.name === 'KOZYREK33' || mesh.name === 'KOZYREK26') {
      // firstBB = new Box3().setFromObject(mesh);

      // if (boundingBoxHelper) {
      //   scene.remove(boundingBoxHelper)
      // }
      // boundingBoxHelper = new Box3Helper(firstBB, 0xffff00);
      // scene.add(boundingBoxHelper);

      if (mesh.name === 'KOZYREK26') {
        firstBB = mesh

      }
      if (mesh.name === 'KOZYREK33') {
        secondBB = mesh
      }
      setTimeout(() => {
        // var collision = firstBB.intersectsBox(secondBB);
        // console.log(collision)
        // if (!collision) {
        //   // currentRoofPositionY -= 1
        // }

        detectCollision(firstBB, secondBB)
      }, 1);
    }
  })


  // modelObj.current3DModel.traverse(mesh => {
  //   if (mesh.name === "KOZYREK33" ||
  //     mesh.name === "KOZYREK29" ||
  //     mesh.name === "KOZYREK31") {
  //     // mesh.position.y = currentRoofPositionY
  //     mesh.position.y = currentRoofPositionY - 370.333 * modelObj.currentModel.scale + yPos2;

  //   }
  // })
}

function detectCollision(object1, object2) {
  object1.geometry.computeBoundingBox();
  object1.updateMatrixWorld();
  var box1 = object1.geometry.boundingBox.clone();
  box1.applyMatrix4(object1.matrixWorld);



  object2.geometry.computeBoundingBox();
  // object2.geometry.computeFaceNormals();
  object2.updateMatrixWorld();
  var box2 = object2.geometry.boundingBox.clone();
  box2.applyMatrix4(object2.matrixWorld);


  if (boundingBoxHelper) {
    scene.remove(boundingBoxHelper)
  }
  boundingBoxHelper = new Box3Helper(box1, 0xffff00);
  scene.add(boundingBoxHelper);

  if (boundingBoxHelper2) {
    scene.remove(boundingBoxHelper2)
  }
  // boundingBoxHelper2 = new VertexNormalsHelper(object2, 2, 0x00ff00, 1);

  boundingBoxHelper2 = new Box3Helper(box2, 0xffffff);
  scene.add(boundingBoxHelper2);

  console.log(box1.intersectsBox(box2))

  // var tempGeo = new Geometry().fromBufferGeometry(object1.geometry);
  // var tempGeo2 = new Geometry().fromBufferGeometry(object2.geometry);
  // object2.geometry.vertices = tempGeo2.vertices
  // object1.geometry.vertices = tempGeo.vertices
  // console.log(object1.matrixWorld)

  // var rcaster = new Raycaster();

  // const position = object1.geometry.attributes.position;
  // const origin = new Vector3();

  // for (let i = 0, l = position.count; i < l; i++) {

  //   origin.fromBufferAttribute(position, i);
  //   origin.applyMatrix4(object1.matrixWorld);

  // }

  // for (var i = 0; i < object2.geometry.vertices.length; i++) {

  //   var dirv = object2.geometry.vertices[i].clone().applyMatrix4(object2.matrix).sub(object2.position);

  //   rcaster.set(origin, dirv.clone().normalize());

  //   var intersects = rcaster.intersectObject(object1);
  //   if (intersects.length && intersects[0].distance < dirv.length()) {
  //     console.log(intersects)
  //   }
  // }

}


export function setRoofHeight() {
  modelObj.current3DModel.traverse(mesh => {
    if (mesh.name === "KOZYREK33" ||
      mesh.name === "KOZYREK29" ||
      mesh.name === "KOZYREK31") {
      mesh.scale.y = modelObj.currentModel.scale * (modelObj.currentModel._params.TOPHEIGHT.value / 200);
    }

    if (mesh.name === "KOZYREK33" ||
      mesh.name === "KOZYREK29" ||
      mesh.name === "KOZYREK31") {
      const k = ((yPos2 - 370.333 * modelObj.currentModel.scale) + (200 - modelObj.currentModel._params.TOPHEIGHT.value)) / 200
      currentRoofPositionY = (k / modelObj.currentModel.scale) * 10
      mesh.position.y = currentRoofPositionY - 370.333 * modelObj.currentModel.scale + yPos2
    }
    adjustModelsY3(mesh)

  })
}

// var k2 = (modelObj.currentModel._params.BOTTOMHEIGHT.value + modelObj.currentModel._params.RAILSHEIGHT.value - 2 * modelObj.currentModel._params.TOPHEIGHT.value * (modelObj.currentModel._params.VYLET.value + 50) / (modelObj.currentModel._params.WIDTH.value + 2 * modelObj.currentModel._params.VYLET.value))



export function setOtlyv() {
  if (modelObj.currentModel._advancedParams.OTLYV.value === true) {
    modelObj.current3DModel.traverse(mesh => {
      if (mesh instanceof Mesh && mesh.name === "OTKOS1" ||
        mesh.name === "OTKOS4" ||
        mesh.name === "OTKOS2" ||
        mesh.name === "OTKOS3") {
        mesh.material.transparent = false;
        mesh.material.opacity = 1;
      }
      if (mesh.name === "OTKOS29" ||
        mesh.name === "OTKOS26" ||
        mesh.name === "OTKOS19" ||
        mesh.name === "OTKOS20" ||
        mesh.name === "OTKOS15") {
        mesh.material.transparent = true;
        mesh.material.opacity = 0;
      }
    })
  } else {
    modelObj.current3DModel.traverse(mesh => {
      if (mesh instanceof Mesh && mesh.name === "OTKOS1" ||
        mesh.name === "OTKOS4" ||
        mesh.name === "OTKOS2" ||
        mesh.name === "OTKOS3") {
        mesh.material.transparent = true;
        mesh.material.opacity = 0;
      }
      if (mesh.name === "OTKOS29" ||
        mesh.name === "OTKOS26" ||
        mesh.name === "OTKOS19" ||
        mesh.name === "OTKOS20" ||
        mesh.name === "OTKOS15") {
        mesh.material.transparent = false;
        mesh.material.opacity = 1;
      }
    })
  }
}

export function otkosSetWidth() {
  modelObj.current3DModel.scale.x = modelObj.currentModel._params.WIDTH.value / 1000
}

export function otkosSetHeight() {
  modelObj.current3DModel.scale.y = modelObj.currentModel._params.HEIGHT.value / 1200
}

export function otkosSetDepth() {
  modelObj.current3DModel.scale.z = modelObj.currentModel._params.DEPTH.value / 200
}

export function detectAngle() {
  var vec1 = new Vector3(0, 0, 1);
  var vec2 = new Vector3(0, 0, 1);
  modelObj.current3DModel.traverse(mesh => {
    if (mesh.name === "OTKOS30") {
      vec1.applyQuaternion(mesh.quaternion);
    }
    if (mesh.name === "OTKOS27") {
      vec2.applyQuaternion(mesh.quaternion);
    }
    setTimeout(() => {
      var angle = vec1.angleTo(vec2);
      console.log(angle)
    }, 1000);
  })
}
