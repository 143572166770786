import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable({
    providedIn: 'root'
})

export class PublicationService {
    public url: string;

    constructor(private _http: HttpClient) {
        this.url = GLOBAL.url;
    }

    addPublication(publication): Observable<any> {
        let params = JSON.stringify(publication);
        return this._http.post(`${this.url}publication`, params);
    }

    getPublication(page = 1): Observable<any> {
        return this._http.get(`${this.url}publications/` + page);
    }

    deletePublication(id, file): Observable<any> {
        let headers = new HttpHeaders();
        var obj = {
            id: id,
            file: file
        }
        const options = {
            headers: headers,
            body: obj,
        };
        return this._http.delete(`${this.url}publication/`, options)
    }

    getSiglePublication(id): Observable<any> {
        return this._http.get(`${this.url}publication/${id}`);
    }

}
