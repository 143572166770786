import {
  Mesh,
  PlaneGeometry,
  MeshPhongMaterial,
  Box3,
  Color,
  Vector2,
  Raycaster,
  PerspectiveCamera,
  SpotLight,
  AmbientLight,
  Vector3,
  Object3D,
  Fog,
} from 'three'
import Stats from 'three/examples/jsm/libs/stats.module.js';
import {
  sceneObj,
  modelObj,
  rendererObj,
  loading
} from './global.js'
import {
  initControls
} from './controls.js'

var
  stats,
  statsContainer,
  plane,
  selectedObject,
  mouse = new Vector2(),
  raycaster = new Raycaster();

const settings = {
  gui: true,
  advanced: {
    STATS: {
      nameRu: "Счетчик фпс",
      value: false,
      func: setupStats
    },
    SHADOWS: {
      value: false,
      nameRu: "Тени",
      func: setupShadows
    },
    FLOOR: {
      value: true,
      nameRu: "Пол",
      func: setFloor
    },
    OPACITY: {
      value: 1,
      nameRu: "Непрозрачность",
      func: setOpacity,
      min: 0.1,
      max: 1,
      step: 0.1
    },
    LIMITPOLARANGLE: {
      value: true,
      nameRu: "Ограничение угла просмотра",
      func: setControlsPolarAngle
    },
    INTERSECTION: {
      value: false,
      nameRu: "Подсветка при наведении"
    },
    ANIMATION: {
      value: 0,
      nameRu: "Анимация",
      min: 0,
      max: 0.005,
      step: 0.0001
    },
    BACKGROUNDCOLOR: {
      value: 16777215,
      nameRu: "Фон",
      func: setBg
    },
  },
  camera: {
    POSITIONZ: {
      value: 900
    },
    FOV: {
      value: 50
    },
    ZOOM: {
      value: 2.25
    }
  },
  spotLight: {
    INTENSITY: 0.9,
    COLOR: 16777215,
    POSITIONY: 750,
    DISTANCE: 2500
  },
  ambientLight: {
    COLOR: 16777215,
    INTENSITY: 0.3
  },
  colors: {
    WHITE: 16777215,
    BLACK: 0,
    GREY: 0xd7d7d7
  },
  renderer: {
    exposure: 1,
    encoding: 3001
  },
  fog: {
    near: 1000,
    far: 3000
  }
}

function setupStats() {
  if (stats) {
    document.getElementById('statsNativeElement').remove()
    stats = null
  }
  if (!stats && settings.advanced.STATS.value) {
    statsContainer = document.createElement('div');
    statsContainer.setAttribute('id', 'statsNativeElement');
    rendererObj.canvasContainer.appendChild(statsContainer)
    stats = new Stats();
    stats.dom.style.position = 'absolute'
    statsContainer.appendChild(stats.dom);
  }
}

function setupShadows() {
  sceneObj.spotLight.castShadow = settings.advanced.SHADOWS.value;
  rendererObj.renderer.shadowMap.enabled = settings.advanced.SHADOWS.value;
  rendererObj.renderer.shadowMapSoft = settings.advanced.SHADOWS.value;
  sceneObj.spotLight.shadowMapSoft = settings.advanced.SHADOWS.value;
  sceneObj.spotLight.castShadow = settings.advanced.SHADOWS.value;
  // modelObj.current3DModel.traverse(res => {
  //   if (res instanceof Mesh) {
  //     res.castShadow = settings.advanced.SHADOWS.value;
  //     res.material.needsUpdate = true
  //   }
  // })
}

function addPlane() {
  var geometry = new PlaneGeometry(10000, 10000);
  var material = new MeshPhongMaterial({
    color: settings.colors.GREY,
  });
  plane = new Mesh(geometry, material);
  plane.rotation.set(-Math.PI * 0.5, 0, 0)
  plane.receiveShadow = true;
  plane.name = 'floor'
  sceneObj.scene.add(plane)
}

function setFloor() {
  if (settings.advanced.FLOOR.value) {
    addPlane()
    const box = new Box3().setFromObject(modelObj.current3DModel);
    plane.position.y = box.min.y;
  } else {
    sceneObj.scene.remove(plane)
  }
}

function setBg() {
  sceneObj.scene.background = new Color(settings.advanced.BACKGROUNDCOLOR.value);
  sceneObj.scene.fog.color = new Color(settings.advanced.BACKGROUNDCOLOR.value);
}

function setOpacity() {
  modelObj.current3DModel.traverse(res => {
    if (res instanceof Mesh) {
      if (settings.advanced.OPACITY.value != 1) {
        res.material.transparent = true;
      }
      res.material.opacity = settings.advanced.OPACITY.value
    }
  })
}

function setControlsPolarAngle() {
  if (settings.advanced.LIMITPOLARANGLE.value) {
    sceneObj.controls.maxPolarAngle = Math.PI / 2.1;
    sceneObj.controls.minPolarAngle = Math.PI / 5;
  } else {
    sceneObj.controls.maxPolarAngle = 10
    sceneObj.controls.minPolarAngle = 0.01
  }
}

function onMouseMove(e) {
  if (!settings.advanced.INTERSECTION.value) return
  e.preventDefault();
  if (selectedObject) {
    if (selectedObject.name !== 'floor') {
      selectedObject.material.color.set(modelObj.currentModel._params.COLOR.value);
      selectedObject = null;
    }
  }

  mouse.x = (e.clientX / rendererObj.renderer.domElement.clientWidth) * 2 - 1;
  // var coords = e.clientY - (window.innerHeight - renderer.domElement.clientHeight)
  var coords = e.clientY
  var y = -(coords / rendererObj.renderer.domElement.clientHeight) * 2 + 1;

  mouse.y = y

  raycaster.setFromCamera(mouse, sceneObj.camera);
  var intersects
  intersects = raycaster.intersectObjects(sceneObj.scene.children, true);
  if (intersects.length > 0) {
    selectedObject = intersects[0].object;
    if (selectedObject instanceof Mesh && selectedObject.name !== 'floor') {
      console.log('Name:' + selectedObject.name);
      selectedObject.material.color.set('#f00');
    }
  }
}

function configureRenderer() {
  // rendererObj.renderer.setPixelRatio(window.devicePixelRatio);
  rendererObj.renderer.setSize(rendererObj.canvasContainer.clientWidth, rendererObj.canvasContainer.clientHeight);
  rendererObj.renderer.outputEncoding = settings.renderer.encoding;
  rendererObj.renderer.toneMappingExposure = settings.renderer.exposure;
}

function setupCamera() {
  sceneObj.camera = new PerspectiveCamera(settings.camera.FOV.value, rendererObj.canvasContainer.clientWidth / rendererObj.canvasContainer.clientHeight, 1, 20000);
  sceneObj.camera.position.z = settings.camera.POSITIONZ.value;
  sceneObj.camera.position.x = 0;
  sceneObj.camera.lookAt(new Vector3(0, 0, 0));
  sceneObj.camera.zoom = settings.camera.ZOOM.value
  sceneObj.camera.updateProjectionMatrix()
}

function setupLight() {
  sceneObj.scene.add(new AmbientLight(settings.ambientLight.COLOR, settings.ambientLight.INTENSITY));
  sceneObj.spotLight = new SpotLight(settings.spotLight.COLOR, settings.spotLight.INTENSITY);
  sceneObj.spotLight.position.set(0, settings.spotLight.POSITIONY, 0);
  sceneObj.spotLight.angle = Math.PI / 6;
  sceneObj.spotLight.distance = settings.spotLight.DISTANCE;
  sceneObj.spotLight.penumbra = 0.7;
  sceneObj.scene.add(sceneObj.spotLight);
  sceneObj.spotLight.target = new Object3D();
  sceneObj.scene.add(sceneObj.spotLight.target)
}

function setupEnvironment() {
  sceneObj.scene.fog = new Fog(settings.advanced.BACKGROUNDCOLOR.value, settings.fog.near, settings.fog.far);
  sceneObj.scene.background = new Color(settings.advanced.BACKGROUNDCOLOR.value)
}

function setupControls() {
  rendererObj.renderer.domElement.addEventListener('mousemove', e => onMouseMove(e), false);

  sceneObj.controls = initControls(sceneObj.camera, rendererObj.renderer.domElement)
  setControlsPolarAngle()

  rendererObj.renderer.domElement.addEventListener('mousedown', _ => {
    rendererObj.isDragging = true;
  });

  rendererObj.renderer.domElement.addEventListener('mouseup', _ => {
    rendererObj.isDragging = false;
  });
}
export {
  stats,
  setupStats,
  settings,
  setupShadows,
  addPlane,
  plane,
  setControlsPolarAngle,
  onMouseMove,
  setupLight,
  setupCamera,
  configureRenderer,
  setupControls,
  setupEnvironment
}
