import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js';

export function initControls(camera, canvas) {

  var controls
  controls = new OrbitControls(camera, canvas)
  controls.maxDistance = 1250
  controls.minDistance = 100
  controls.enableDamping = true;
  controls.dampingFactor = 0.125;
  controls.enableZoom = true;
  controls.autoRotate = false;
  controls.enablePan = false;

  controls.maxPolarAngle = Math.PI / 2.25;
  controls.minPolarAngle = Math.PI / 3;
  return controls

}
