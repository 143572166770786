import {
  Scene
} from 'three';
import {
  BehaviorSubject
} from 'rxjs';

let sceneObj = {
  scene: new Scene(),
  controls: null,
  camera: null,
  spotLight: null
}

let loading = new BehaviorSubject(true)

let rendererObj = {
  renderer: null,
  canvas: null,
  canvasContainer: null,
  isDragging: false
}

let modelObj = {
  current3DModel: null,
  currentModel: null
}

function loadingStatus() {
  return loading.asObservable()
}

export {
  modelObj,
  sceneObj,
  rendererObj,
  loadingStatus,
  loading,
};
