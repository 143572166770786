import { Injectable } from '@angular/core';
import {
  OBJLoader
} from 'three/examples/jsm/loaders/OBJLoader'
import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader'
import {
  models,
  path
} from 'src/app/scene/models.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

@Injectable({
  providedIn: 'root'
})
export class LoadmodelsService {

  loader = new OBJLoader()
  loaderGLTF = new GLTFLoader()
  dracoLoader = new DRACOLoader();

  configureDracoLoader() {
    this.dracoLoader.setDecoderPath('assets/decoders/draco/');
    this.loaderGLTF.setDRACOLoader(this.dracoLoader);
  }

  loadModelById(id) {
    return new Promise(resolve => {
      this.loader.load(models[id].file, resolve)
    });
  }

  loadAllModels() {
    let promises = [];

    models.forEach((element, i) => {
      promises.push(
        this.loadModelById(i)
      );
    });

    return Promise.all(promises)
  }

}
