import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SceneComponent } from './scene/scene.component';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from 'src/app/utils/reuse-strategy';
import { FormsComponent } from './forms/forms.component';
import { FormsModule } from '@angular/forms';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { GuiComponent } from './gui/gui.component';
import { UploadComponent } from './upload/upload.component';
import { httpInterceptor } from './utils/http-interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    SceneComponent,
    FormsComponent,
    LoadingIndicatorComponent,
    GuiComponent,
    UploadComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    SatPopoverModule,
    HttpClientModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
