import { Injectable } from '@angular/core';
import { modelObj, sceneObj } from 'src/app/scene/global.js'
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { models } from 'src/app/scene/models.js'
@Injectable({
  providedIn: 'root'
})
export class ChangemodelService {

  constructor(
    private _router: Router
  ) { }

  changeModelById(id) {
    sceneObj.scene.remove(modelObj.current3DModel)
    sceneObj.scene.add(models[id]._loaded3DModel)
    modelObj.currentModel = models[id]
    this.nextParams(models[id])
    modelObj.current3DModel = models[id]._loaded3DModel
    for (let KEY in modelObj.currentModel._params) {
      let OBJ = modelObj.currentModel._params[KEY]
      if (OBJ.func) {
        OBJ.func()
      }
    }
    for (let KEY in modelObj.currentModel._advancedParams) {
      let OBJ = modelObj.currentModel._advancedParams[KEY]
      if (OBJ.func) {
        OBJ.func()
      }
    }
  }

  private params = new BehaviorSubject<any>(null)

  getParams() {
    return this.params.asObservable()
  }

  nextParams(obj: any) {
    this.params.next(obj)
  }
}
