import { Injectable, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { WebGLRenderer } from 'three';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RendererService {
  public renderer: WebGLRenderer
  constructor() {
  }

  createRenderer(canvas: ElementRef["nativeElement"]): Observable<WebGLRenderer> {

    this.renderer = new WebGLRenderer({
      antialias: true,
      canvas: canvas,
      alpha: true,
      preserveDrawingBuffer: false
    });
    return of(this.renderer)
  }
}
