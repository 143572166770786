import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { RendererService } from '../services/renderer.service';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { SatPopoverAnchor } from '@ncstate/sat-popover';
import { ChangemodelService } from '../services/changemodel.service';
import { Title } from '@angular/platform-browser';
import {
  MeshPhongMaterial,
  DoubleSide,
} from 'three';

import {
  models,
  path
} from './models.js';

import {
  settings,
  setupStats,
  stats,
  setupShadows,
  setControlsPolarAngle,
  onMouseMove,
  configureRenderer,
  setupCamera,
  setupLight,
  setupEnvironment,
  setupControls
} from './settings'

import {
  sceneObj,
  modelObj,
  rendererObj,
  loading,
  loadingStatus,
} from './global.js'

import {
  centerModels,
  adjustFloor,
  GETCOLOR
} from './adjust.js'
import { rotation } from './animations.js'
import { LoadmodelsService } from '../services/loadmodels.service';

@Component({
  selector: 'scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.scss'],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class SceneComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas', { static: true }) canvas: ElementRef
  @ViewChild('sceneDiv', { static: true }) sceneDiv: ElementRef
  @Input() height
  public _models = models
  public loading: boolean
  anchor: SatPopoverAnchor;
  currentRoute
  loadedModels = []

  constructor(
    private _rendererSvc: RendererService,
    public _router: Router,
    private _route: ActivatedRoute,
    public _changeModelSvc: ChangemodelService,
    private _title: Title,
    private _loadModelsSvc: LoadmodelsService
  ) {
  }

  ngOnInit() {
    loadingStatus().subscribe(res => {
      this.loading = res
    })

    this._router.events.subscribe((res) => {
      if (res instanceof RoutesRecognized) {
        if (this.anchor) {
          this._closeMenu()
        }
      }
    })

    if (this.height) {
      this.sceneDiv.nativeElement.style.height = `${this.height}vh`
    }
  }

  ngAfterViewInit() {
    this._rendererSvc.createRenderer(this.canvas.nativeElement).subscribe(res => {
      rendererObj.renderer = res
      rendererObj.canvasContainer = this.sceneDiv.nativeElement
      this.startScene()
    })
  }

  initRoutes() {
    this._route.queryParams.subscribe(res => {
      this.currentRoute = res.modelId
      if (!this.loadedModels[this.currentRoute]) {
        this.loadModels()
        return
      }
      this.changeModel()
    })
  }

  newtab(url: string) {
    window.open(url, '_blank')
  }

  _openMenu(anchor: SatPopoverAnchor) {
    this.anchor = anchor
    this.anchor.popover.open();
  }

  _closeMenu() {
    this.anchor.popover.close();
  }

  setupScene() {
    configureRenderer()
    setupCamera()
    setupLight()
    setupEnvironment()
    setupControls()
  }

  startScene() {
    this.setupScene()
    this.initRoutes()
    setupShadows()
    setupStats()
    this.startRendering()
  }

  loadModels() {
    loading.next(true)
    this._loadModelsSvc.loadModelById(this.currentRoute).then(res => {
      this.loadedModels[this.currentRoute] = res
      this.setupModels(this.currentRoute)
      this.changeModel()
      loading.next(false)
    })
  }

  changeModel() {
    this._changeModelSvc.changeModelById(this.currentRoute)
    this._title.setTitle(`Details scene - ${modelObj.currentModel.name}`)
    adjustFloor()
  }

  setupModels(i) {
    this.loadedModels[i].children.forEach(mesh => {
      mesh.name = models[i].nameEn + mesh.name
      mesh.material = new MeshPhongMaterial({
        shininess: 69,
        color: models[i]._params.COLOR.value,
        side: DoubleSide,
      })
      // mesh.position.set(0, 0, 0)
      mesh.scale.set(models[i].scale, models[i].scale, models[i].scale)
      mesh.material.precision = 'mediump'
      mesh.castShadow = true
      models[i]._loaded3DModel.add(mesh.clone())
      centerModels(models[i]._loaded3DModel)
    });
  }

  startRendering() {
    rendererObj.renderer.setAnimationLoop(this.animate)
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        rendererObj.renderer.setAnimationLoop(this.animate)
      } else {
        rendererObj.renderer.setAnimationLoop();
      }
    });
  }

  animate() {
    if (stats) {
      stats.begin()
    }
    rotation()
    rendererObj.renderer.render(sceneObj.scene, sceneObj.camera);
    if (stats) {
      stats.end();
    }
  };

  onResize() {
    sceneObj.camera.aspect = rendererObj.canvasContainer.clientWidth / rendererObj.canvasContainer.clientHeight;
    sceneObj.camera.updateProjectionMatrix();
    rendererObj.renderer.setSize(rendererObj.canvasContainer.clientWidth, rendererObj.canvasContainer.clientHeight);
  }

  nextModel() {
    modelObj.currentModel._params.COLOR.value = GETCOLOR()
    this.currentRoute++;
    if (this.currentRoute >= this._models.length) {
      this.currentRoute = 0;
    };
    this._router.navigate(['scene/'], { queryParams: { modelId: this.currentRoute } });
  }
}
