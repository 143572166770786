import { Injectable } from '@angular/core';
import { GLOBAL } from './global';

@Injectable({
    providedIn: 'root'
})

export class UploadService {
    public url: string;

    constructor(
    ) {
        this.url = GLOBAL.url;
    }

    makeFileRequest(url, params: {}, files, token: string, name: string) {
        return new Promise(function (resolve, reject) {
            var formData: any = new FormData();
            var xhr = new XMLHttpRequest();

            // for (var i = 0; i < files.length; i++) {
            formData.append(name, files, files.name);
            // }

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            }

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', token);
            xhr.send(formData);
        });
    }



}
