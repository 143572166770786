import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  GUI
} from 'three/examples/jsm/libs/dat.gui.module.js';
import {
  settings
} from 'src/app/scene/settings.js'
import { modelObj } from 'src/app/scene/global.js'
import { Router } from '@angular/router';
import { ChangemodelService } from '../services/changemodel.service';
@Component({
  selector: 'gui',
  templateUrl: './gui.component.html',
  styleUrls: ['./gui.component.scss']
})
export class GuiComponent implements OnInit {
  @ViewChild('guiContainer', { static: true }) guiContainer: ElementRef

  private modelFolder;
  private advancedSettingsFolder;
  private gui: GUI;
  PARAMS

  constructor(
    public _router: Router,
    private _changeModelSvc: ChangemodelService
  ) { }

  ngOnInit(): void {
    this._changeModelSvc.getParams().subscribe(res => {
      this.PARAMS = res
      this.redrawGui()
    })
  }

  initGui() {
    if (settings.gui) {
      this.gui = new GUI({
        load: JSON,
        width: 300,
        autoPlace: false
      });
      this.guiContainer.nativeElement.appendChild(this.gui.domElement);
    }
  }

  redrawGui() {
    if (this.gui) {
      this.gui.destroy()

      this.modelFolder = this.gui.addFolder(this.PARAMS.name)
      this.modelFolder.open()

      for (let KEY in this.PARAMS._params) {
        let OBJ = this.PARAMS._params[KEY]
        if (OBJ.nameRu !== 'Цвет') {
          this.modelFolder.add(OBJ, 'value').min(OBJ.min).max(OBJ.max).name(OBJ.nameRu).onChange(value => {
            if (OBJ.func) {
              OBJ.func()
            }
          })
        } else {
          this.modelFolder.addColor(OBJ, 'value').name(OBJ.nameRu).onChange(value => {
            if (OBJ.func) {
              OBJ.func()
            }
          })
        }
      }

      for (let KEY in this.PARAMS._advancedParams) {
        let OBJ = this.PARAMS._advancedParams[KEY]

        this.modelFolder.add(OBJ, 'value').name(OBJ.nameRu).onChange(value => {
          if (OBJ.func) {
            OBJ.func()
          }
        })
      }

      this.addAdvancedSettingsFolder()
    }
    else {
      this.initGui()
    }
  }

  addAdvancedSettingsFolder() {

    this.advancedSettingsFolder = this.gui.addFolder('Доп настройки')

    for (let KEY in settings.advanced) {
      let OBJ = settings.advanced[KEY]
      if (!OBJ.step && OBJ.nameRu !== 'Фон') {
        this.advancedSettingsFolder.add(OBJ, 'value').name(OBJ.nameRu).onChange(value => {
          if (OBJ.func) {
            OBJ.func()
          }
        })
      }
      if (OBJ.nameRu === 'Фон') {
        this.advancedSettingsFolder.addColor(OBJ, 'value').name(OBJ.nameRu).onChange(value => {
          if (OBJ.func) {
            OBJ.func()
          }
        })
      }
      if (OBJ.step) {
        this.advancedSettingsFolder.add(OBJ, 'value').name(OBJ.nameRu).min(OBJ.min).max(OBJ.max).step(OBJ.step).onChange(value => {
          if (OBJ.func) {
            OBJ.func()
          }
        })
      }
    }
  }

}
