import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoadmodelsService } from 'src/app/services/loadmodels.service';
import {
  MeshPhongMaterial,
  DoubleSide,
  Group,
  BoxGeometry,
  MeshBasicMaterial,
  Mesh,
} from 'three';

import {
  models,
  path
} from 'src/app/scene/models.js';

import {
  settings,
  setupStats,
  stats,
  setupShadows,
  setControlsPolarAngle,
  onMouseMove,
  configureRenderer,
  setupCamera,
  setupLight,
  setupEnvironment,
  setupControls
} from 'src/app/scene/settings'

import {
  sceneObj,
  modelObj,
  rendererObj,
  loading,
  loadingStatus,
} from 'src/app/scene/global.js'

import { animation } from 'src/app/scene/animations.js'

import {
  centerModels,
  getSize,
  adjustFloor
} from 'src/app/scene/adjust.js'
import { rotation } from 'src/app/scene/animations.js'
import { RendererService } from '../services/renderer.service';
import { ChangemodelService } from '../services/changemodel.service';
import {
  setScale,
  setColor,
  otkosSetWidth,
  otkosSetHeight,
  otkosSetDepth,
  GETCOLOR
} from 'src/app/scene/adjust.js'
import { Title } from '@angular/platform-browser';
import { PublicationService } from '../services/publication.service';
import { Publication } from '../models/publication';
import { UploadService } from '../services/upload.service';
import { GLOBAL } from '../services/global';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class UploadComponent implements OnInit {
  loadedModel
  @ViewChild('canvas', { static: true }) canvas: ElementRef
  @ViewChild('sceneDiv', { static: true }) sceneDiv: ElementRef
  loading
  fileToUpload
  publication: Publication;
  page = 1
  token = "token1"
  url = GLOBAL.url
  constructor(
    private _loadModelSvc: LoadmodelsService,
    private _rendererSvc: RendererService,
    private _changeModelSvc: ChangemodelService,
    private _title: Title,
    private _publicationSvc: PublicationService,
    private _uploadSvc: UploadService

  ) {
    this.publication = new Publication("", "", "", "", '1');
  }

  ngOnInit(): void {
    this._rendererSvc.createRenderer(this.canvas.nativeElement).subscribe(res => {
      rendererObj.renderer = res
      rendererObj.canvasContainer = this.sceneDiv.nativeElement
      this.startScene()
    })
    this._loadModelSvc.configureDracoLoader()

  }

  fileChange(e) {
    this.loading = true
    if (e.target.files.length === 0) {
      this.loading = false
      return
    }
    const file = e.target.files[0];

    var extension = file.name.split('.').pop().toLowerCase();
    var requiredExt = ["obj", "glb", "gltf"]
    var ext = requiredExt.includes(extension)
    if (!ext) {
      alert(`Supported extensions: ${requiredExt}`)
      this.loading = false
      return
    }

    var reader = new FileReader();

    reader.addEventListener('load', (event) => {

      var contents = event.target.result;

      if (extension === 'obj') {
        var object = this._loadModelSvc.loader.parse(contents as string);
        this.loadedModel = object
        this.cleanUp()
      }
      if (extension === 'glb' || extension === 'gltf') {
        this._loadModelSvc.loaderGLTF.parse(contents, '', res => {
          this.loadedModel = res.scene
          this.cleanUp()
        });
      }

    }, false);


    if (extension === 'glb' || extension === 'gltf') {
      reader.readAsArrayBuffer(file);
    }

    if (extension === 'obj') {
      reader.readAsText(file);
      this.fileToUpload = file

    }
  }

  cleanUp() {
    this._title.setTitle(`Details scene - ${this.loadedModel.uuid}`)

    sceneObj.scene.remove(models[0]._loaded3DModel)
    models[0]._loaded3DModel = new Group()
    let obj = {
      name: this.loadedModel.uuid,
      _params: {
        SCALE: {
          value: 1,
          min: 0.3,
          max: 2,
          nameRu: "Масштаб",
          func: setScale
        },
        COLOR: {
          value: GETCOLOR(),
          nameRu: "Цвет",
          func: setColor
        },
        WIDTH: {
          value: 1000,
          min: 500,
          max: 3000,
          nameRu: "Ширина",
          func: otkosSetWidth
        },
        HEIGHT: {
          value: 1200,
          min: 500,
          max: 3000,
          nameRu: "Высота",
          func: otkosSetHeight
        },
        DEPTH: {
          value: 200,
          min: 100,
          max: 500,
          nameRu: "Глубина",
          func: otkosSetDepth
        }
      },
      _advancedParams: {
        ANIMATION: {
          value: false,
          nameRu: "Анимация",
          func: animation
        },
      }
    }
    modelObj.currentModel = obj
    this._changeModelSvc.nextParams(obj)
    this.setupModels()
    this.loading = false
    adjustFloor()
  }

  setupScene() {
    configureRenderer()
    setupCamera()
    setupLight()
    setupEnvironment()
    setupControls()
  }

  startScene() {
    this.setupScene()
    setupShadows()
    setupStats()
    this.startRendering()

    var geometry = new BoxGeometry(250, 250, 250);
    var material = new MeshBasicMaterial({ color: 0x00ff00 });
    material.transparent = true
    material.opacity = 0
    var cube = new Mesh(geometry, material);
    sceneObj.scene.add(cube);
  }

  setupModels() {
    this.loadedModel.children.forEach(mesh => {
      mesh.material = new MeshPhongMaterial({
        shininess: 69,
        color: modelObj.currentModel._params.COLOR.value,
        side: DoubleSide,
      })
      // mesh.position.set(0, 0, 0)
      mesh.scale.set(0.15, 0.15, 0.15)
      mesh.material.precision = 'mediump'
      mesh.castShadow = true
      models[0]._loaded3DModel.add(mesh.clone())
    });

    sceneObj.scene.add(models[0]._loaded3DModel)
    modelObj.current3DModel = models[0]._loaded3DModel

    var modelSize = getSize(models[0]._loaded3DModel)
    var sceneSize = getSize(sceneObj.scene)
    var ratio = modelSize.divide(sceneSize)

    models[0]._loaded3DModel.scale.set(0.15 * (1 / ratio.y), 0.15 * (1 / ratio.y), 0.15 * (1 / ratio.y))
    centerModels(models[0]._loaded3DModel)
  }

  startRendering() {
    rendererObj.renderer.setAnimationLoop(this.animate)
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        rendererObj.renderer.setAnimationLoop(this.animate)
      } else {
        rendererObj.renderer.setAnimationLoop();
      }
    });
  }

  animate() {
    if (stats) {
      stats.begin()
    }
    rotation()
    rendererObj.renderer.render(sceneObj.scene, sceneObj.camera);
    if (stats) {
      stats.end();
    }
  };

  onResize() {
    sceneObj.camera.aspect = rendererObj.canvasContainer.clientWidth / rendererObj.canvasContainer.clientHeight;
    sceneObj.camera.updateProjectionMatrix();
    rendererObj.renderer.setSize(rendererObj.canvasContainer.clientWidth, rendererObj.canvasContainer.clientHeight);
  }


  onSubmit(form, event) {
    this._publicationSvc.addPublication(this.publication).subscribe(res => {
      console.log(res)
      if (res.publication) {
        // upload file
        if (this.fileToUpload) {
          this._uploadSvc
            .makeFileRequest(this.url + 'upload-image-pub/' + res.publication._id, [], this.fileToUpload, this.token, 'file')
            .then((result: any) => {
              this.publication.file = result.image;
              form.reset();

              this._publicationSvc.getPublication(this.page).subscribe(
                res => { console.log(res) })
              return
            }
            )
        } else {
          form.reset();
        }
      }
    },
      error => {
        console.log(error)
      }
    );
  }

}
