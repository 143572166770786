import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SceneComponent } from './scene/scene.component';
import { FormsComponent } from './forms/forms.component';
import { UploadComponent } from './upload/upload.component';


const routes: Routes = [
  { path: 'upload', component: UploadComponent },
  { path: 'scene', component: SceneComponent, data: { reuseRoute: true } },
  { path: 'forms', component: FormsComponent, data: { reuseRoute: true } },
  { path: '', redirectTo: '/scene?modelId=1', pathMatch: 'full' },
  { path: '**', redirectTo: '/scene?modelId=1', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
