import {
  TimelineMax,
  Linear
} from "gsap";

import {
  sceneObj,
  rendererObj,
  modelObj
} from './global.js'

import {
  settings
} from './settings.js'

var max = Math.PI / 1.87;

function rotation() {
  sceneObj.controls.update()
  var x = sceneObj.camera.position.x;
  var z = sceneObj.camera.position.z;
  var y = sceneObj.camera.position.y;
  if (rendererObj.isDragging) {
    sceneObj.camera.prevX = sceneObj.camera.position.x;
    sceneObj.camera.prevZ = sceneObj.camera.position.z;
  }

  if (!rendererObj.isDragging) {

    if (sceneObj.camera.prevX < sceneObj.camera.position.x) {
      sceneObj.camera.position.x = x * Math.cos(settings.advanced.ANIMATION.value) + z * Math.sin(settings.advanced.ANIMATION.value);
    } else {
      sceneObj.camera.position.x = x * Math.cos(settings.advanced.ANIMATION.value) - z * Math.sin(settings.advanced.ANIMATION.value);
    }

    if (sceneObj.camera.prevZ < sceneObj.camera.position.z) {
      sceneObj.camera.position.z = z * Math.cos(settings.advanced.ANIMATION.value) + x * Math.sin(settings.advanced.ANIMATION.value);
    } else {
      sceneObj.camera.position.z = z * Math.cos(settings.advanced.ANIMATION.value) - x * Math.sin(settings.advanced.ANIMATION.value);
    }

    if (sceneObj.camera.position.y >= max) {
      sceneObj.camera.position.y = y * Math.cos(settings.advanced.ANIMATION.value) - y * Math.sin(settings.advanced.ANIMATION.value);
    }
    if (sceneObj.camera.position.y <= max) {
      sceneObj.camera.position.y = y * Math.cos(settings.advanced.ANIMATION.value) + y * Math.sin(settings.advanced.ANIMATION.value);
    }
  }

  sceneObj.camera.lookAt(sceneObj.scene.position)
}

var RANDOM = (min, max) => {
  var rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}
var TWEEN,
  MOVE;

function animation() {
  if (modelObj.currentModel._advancedParams.ANIMATION.value === false) return

  TWEEN = (fracturePosition, fractureRotation) => {
    MOVE = new TimelineMax({
      ease: Linear.easeNone,
    })

    MOVE.to(fracturePosition, 2.75, {
      x: RANDOM(-100, 100),
      y: RANDOM(-100, 100),
      z: RANDOM(-100, 100),
    })
    MOVE.to(fracturePosition, 1, {
      x: 0,
      y: 0,
      z: 0,
      onComplete: () => {
        setTimeout(() => {
          if (modelObj.currentModel._advancedParams.ANIMATION.value) {
            TWEEN(fracturePosition, fractureRotation)
          }
        }, 1000);

      },
    });
  }
  modelObj.current3DModel.children.forEach(element => {
    TWEEN(element.position, element.rotation);
  })
}

export {
  rotation,
  animation
}
